import React, { Component } from 'react';

class FourOhFour extends Component {
    render() {
        return (
            <div>
                404 page
            </div>
        );
    }
}

export default FourOhFour;